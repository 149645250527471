<template>

  <div class="actions">

    <template v-if="useAuth().isOwner(owner)">

      <UiButtonDropdown
        :name="'Settings'"
        :ico="'icon-settings'"
        :options="actionsEditDiary"
        @remove="$emit('removediary')"
        @unpublish="$emit('unpublishdiary')"
        @publish="$emit('publishdiary')"
        />
     
    </template>

    <template v-else>
      <UiButton
        v-if="!isfollow"
        @click="$emit('follow')"
        name="Follow"
        type="primary"
        :cnt="cnt_followers"
        />

      <UiButton
        v-if="isfollow"
        @click="$emit('unfollow')"
        name="Unfollow"        
        :cnt="cnt_followers"
        />
      
    </template>

  </div>
  
</template>

<script setup>


const props = defineProps({
  owner: {
    type: Number
  },
  data: {
    type: Object
  },
  diary: {
    type: Number
  },
  diaryhidden: {
    type: Boolean
  },
  followers: {
    type: Number
  },
  isfollow: {
    type: [Number, Boolean]
  },
});

const cnt_followers = ref(props.followers);
const is_follow = ref(props.isfollow);

const actionsEditDiary = computed(() => {
  var actions = [];

  actions.push({
    name: 'Edit diary',
    link: '/diaries/edit/' + props.diary
  });

  if(props.diaryhidden)
    actions.push({
      name: 'Publish diary',
      event: 'publish'
    });
  else
    actions.push({
      name: 'Hide diary',
      event: 'unpublish'
    });

  actions.push({
    name: 'Remove diary',
    event: 'remove'
  });

  return actions;
});


watch(() => props.followers, (val) => {
  cnt_followers.value = val;
});
 
 
</script>

<style scoped>

.actions{
  display: flex;
  justify-content: center;
}

@container pb (max-width: 600px) {
  .actions{
    display: none;
  }
}
</style>

<template>

  <ClientOnly>


    <Teleport to="#context" v-if="diary">

      <div class="ctx" @click="activeContext = true">
        <i class="icon-dots"></i>
      </div>
        

      <UiModal
        v-if="activeContext"
        :title="''"
        :close-button-name="'Close'"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'70%'"
        :max-width="'400px'"
        @close="activeContext = false"         
        >        
        
        <div class="context_list">
 
            <img v-if="diary.avatar_small" class="avatar" :src="diary.avatar_small" />
            
            <div 
              class="actions" 
              v-if="useAuth().getId() == diary.item_user.id"
              >    

              <UiButton
                tag="NuxtLink"             
                :name="$t('diary_info_button_edit')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false" 
                :to="'/diaries/edit/' + diary.id"
                />

              <UiButton
                :name="$t('diary_info_button_remove')"
                ico="icon-remove"
                type="float"
                @click.native="activeContext = false; removeDiary()" 
                />

              <UiButton
                v-if="!diary.is_hidden"
                :name="$t('diary_info_button_hide')"
                ico="icon-not-eye"
                type="float"
                @click.native="activeContext = false; unpublishDiary()" 
                />

              <UiButton
                v-if="diary.is_hidden"
                :name="$t('diary_info_button_publish')"
                ico="icon-eye"
                type="float"
                @click.native="activeContext = false; publishDiary()" 
                />

              <UiButton
                :name="$t('diary_info_button_add_week')"
                ico="icon-plus"
                type="float"
                @click.native="activeContext = false; addWeek()" 
                />

              <UiButton
                v-if="week?.id"
                tag="NuxtLink"      
                :name="$t('diary_info_button_edit_week')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false; editWeek()" 
                :to="'/diaries/' + diary.link + '/edit/week/' + week.id"
                />

              <UiButton
                v-if="week?.id"
                tag="NuxtLink"      
                :name="$t('harvest_view_button_edit_smoke')"
                ico="icon-pencil"
                type="float"
                @click.native="activeContext = false; editSmokeWeek()" 
                :to="'/diaries/' + diary.link + '/edit/smoke/' + week.id"
                />

              <UiButton
                :name="$t('diary_info_button_remove_week')"
                ico="icon-remove"
                type="float"
                @click.native="activeContext = false; removeWeek()" 
                />

              <UiButton
                v-if="copyIsSupported"
                :name="$t('diary_info_button_copy_link')"
                ico="icon-share"
                type="float"
                @click.native="activeContext = false; copyLink()" 
                />


            </div>

            <div v-else>
              

              <template v-if="useAuth().isAuth()">

                <!-- <UiButton
                  :name="$t('diary_info_like_week')"
                  ico="icon-leaf"
                  type="float"
                  @click.native="activeContext = false; likeWeek()" 
                  />

                <UiButton
                  :name="$t('diary_info_unlike_week')"
                  ico="icon-leaf"
                  type="float"
                  @click.native="activeContext = false; unlikeWeek()" 
                  /> -->

                <UiButton
                  v-if="!diary?.addon?.follow"
                  :name="$t('diary_info_follow_diary')"
                  ico="icon-diary"
                  type="float"
                  @click.native="activeContext = false; followDiary()" 
                  />

                <UiButton
                  v-if="diary?.addon?.follow"
                  :name="$t('diary_info_unfollow_diary')"
                  ico="icon-diary"
                  type="float"
                  @click.native="activeContext = false; unfollowDiary()" 
                  />

                <UiButton
                  v-if="diary?.item_user?.link && !diary?.item_user?.is_remove && !diary?.item_user?.is_hidden"
                  tag="NuxtLink"      
                  :name="$t('diary_info_show_author') + ' - ' + diary?.item_user?.name"
                  ico="icon-user"
                  type="float"
                  @click.native="activeContext = false;" 
                  :to="diary?.item_user?.link"
                  />

                <UiButton
                  v-if="copyIsSupported"
                  :name="$t('diary_info_button_copy_link')"
                  ico="icon-share"
                  type="float"
                  @click.native="activeContext = false; copyLink()" 
                  />

              </template>
  
            </div> 
        </div>
      </UiModal>
    </Teleport>    
  </ClientOnly>

</template>

<script setup>
 
import { useClipboard } from '@vueuse/core'

const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const emit = defineEmits(['removediary', 'updateweek', 'canceleditweek']);
const props = defineProps({
  diary: {
    type: Object,
    default: null,
  },
  week: {
    type: Object,
    default: null,
  },
})

var activeContext = ref(false)

const removeDiary = function(){
  emit('removediary')
}

const unpublishDiary = function(){
  emit('unpublishdiary')
}

const publishDiary = function(){
  emit('publishdiary')
}

const addWeek = function(){
  emit('addweek')
}

const editWeek = function(){
  emit('editweek')
}

const editSmokeWeek = function(){
  emit('editsmoke')
}

const removeWeek = function(){
  emit('removeweek')
}

const copyLink = function(){
  copy(document.location.href)
}

const likeWeek = function(){
  emit('likeweek')
}

const unlikeWeek = function(){
  emit('unlikeweek')
}

const followDiary = function(){
  emit('followdiary')
}

const unfollowDiary = function(){
  emit('unfollowdiary')
}



 
</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }

  /*  */

  .context_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  .avatar{

    width: 150px;
    margin: 0 auto;
    border-radius: 20px;
    margin-bottom: 1rem;
    aspect-ratio: 1/1;
  }
</style>

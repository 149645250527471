<template>

  <div class="item award_item">
    
    <NuxtLink :to="link">
      <img class="logo" data-ratio="40-40" :src="icon" alt="">
    </NuxtLink>

    <div class="info">
      <NuxtLink :to="link" class="name" :title="name">{{ $plurals.getPlaces(place) + ' ' + name }}</NuxtLink>
      <span class="ttl">{{ $t('diary_info_award') }}</span>
    </div>

  </div>

</template>

<script>
 

export default {   
  props: [
    'icon',
    'name',
    'link',
    'place'
  ],
  data () {
    return {            
    }
  },
  created() {    
    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
  
.item.award_item{
  cursor: help;
}
.item{
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
}
.item .logo{
    max-height: 40px;
    max-width: 40px;
    margin-right: 10px;
    width: 100%;
}
.item .info{
    display: flex;
    flex-direction: column;
    /*width: calc(100% - 46px);*/
}
.item .info .name{
    /* color: var(--gd-text-color); */
    /*max-width: 120px;*/
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}
.item .info .ttl{
    /* overflow: hidden; */
    text-overflow: ellipsis; 
    text-transform: capitalize;
    color: var(--gd-text-gray-color);
    white-space: nowrap;
}
.item .info .ttl .lnk{
    overflow: hidden;
    text-overflow: ellipsis; 
    text-transform: capitalize;
    color: var(--gd-text-gray-color);
    white-space: nowrap;
}


</style>

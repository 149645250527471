import { ref } from "vue";

const activeWikiModal = ref(false)
const dataWikiModal = ref({})

export const useWikiModal = () => {

  const set = (type, id) => {    
    dataWikiModal.value = { type, id }
  }

  const close = () => {   
    activeWikiModal.value = false
    dataWikiModal.value = {}
  } 

  const open = (type, id) => {    
    activeWikiModal.value = true
    dataWikiModal.value = { type, id }
  }

  return { active: activeWikiModal, data: dataWikiModal, set, open, close };
}


<template>   
  <div class="it">
    <div class="nm skl"></div> 
    <div class="st">
      <div class="sti skl"></div> 
      <div class="sti skl"></div> 
      <div class="sti skl"></div> 
    </div> 

    <!--  -->

    <div class="pr">
      <div class="pri"><div class="lg skl"></div><div class="tl skl"></div><div class="hn skl"></div></div> 
      <div class="pri"><div class="lg skl"></div><div class="tl skl"></div><div class="hn skl"></div></div> 
      <div class="pri"><div class="lg skl"></div><div class="tl skl"></div><div class="hn skl"></div></div> 
    </div>  

    <!--  -->

    <div class="hr skl"></div>

    <div class="wk">
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
      <div class="wki skl"></div>
    </div>

    <div class="hr skl"></div>

    <div class="cnt">
      <div class="ph">
        <div class="phi skl"></div>
        <div class="phi skl"></div>
        <div class="phi skl"></div>
      </div>
      <div class="sp">
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
        <div class="spi skl"></div>
      </div>
      <div class="tx skl"></div>
    </div>


  </div>   
</template>

 
  

<style scoped>

.it{
  /* position: absolute;
  top: 73px;
  width: 100%; */
}

.it{
   
}

.hr{
  height: 2px;
  width: 100%;
  margin: 1.6rem;
}

.nm{
  width: 100%;
  max-width: 280px;
  height: 28px;  
}

.st{
  width: 100%;  
  height: 25px;
  margin-top: 5px;
  display: flex;  
}

.st .sti{
  width: 120px;  
  height: 20px;
  margin-right: 10px;
}

/*  */

.pr{
  width: 100%;  
  height: 40px;
  margin-top: 20px;
  display: flex;  
  gap: 1rem;
}

.pr .pri{
  position: relative;
  width: 150px;
}
.pr .pri .lg{
  width: 40px;  
  height: 40px;
  margin-right: 10px;
}
.pr .pri .tl{
  width: 80px;  
  height: 18px; 
  position: absolute;
  left: 50px;
  top: 0px;
}
.pr .pri .hn{
  width: 60px;  
  height: 18px; 
  position: absolute;
  left: 50px;
  top: 20px;
}

/*  */


.wk{
  width: 100%;  
  height: 80px;
  margin-top: 1rem;
  display: flex;  
  overflow-x: hidden;
  justify-content: center;
}

.wk .wki{
  width: 55px;    
  min-width: 55px;    
  margin: 2px;
}

/*  */

.cnt{
  display: flex;
  flex-direction: column;
  align-content: center;
}
.cnt .ph{
  width: 100%;    
  margin-top: 0px;
  display: flex; 
  justify-content: center;
}
.cnt .ph .phi{
  width: 200px;
  height: 200px;
  margin: 5px;
}
.cnt .sp{
  width: 100%;    
  margin-top: 30px;
  display: flex;  
  justify-content: center;
  flex-wrap: wrap;
}
.cnt .sp .spi{
  width: 250px;  
  height: 60px; 
  margin: 5px;
}
.cnt .tx{
  width: 70%;  
  height: 105px;  
  margin: 30px auto;
}

@container pb (max-width: 768px) {
  .cnt .ph .phi:not(:first-child){
    display: none;
  }
  .cnt .ph .phi{
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .hr{
    max-width: 85%;
  }
  .cnt .sp .spi{
    width: 100%;
    height: 40px;
  }
}

</style>
